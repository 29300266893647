import React, { createContext, useContext, useState, useEffect } from 'react';
import { getAllProducts } from 'services/productService';

const ProductsContext = createContext();

export const ProductsProvider = ({ children }) => {
    const [products, setProducts] = useState([]);
    const [isFetched, setIsFetched] = useState(false);

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const productsData = await getAllProducts();
                setProducts(productsData);
            } catch (error) {
                console.error('Erro ao buscar produtos:', error);
            } finally {
                setIsFetched(true);
            }
        };

        fetchProducts();
    }, []);

    return (
        <ProductsContext.Provider value={{ products, isFetched }}>
            {children}
        </ProductsContext.Provider>
    );
};

export const useProducts = () => useContext(ProductsContext);