import React, { useState, useEffect, useRef, useMemo } from 'react';
import {
  getAllProducts,
  deleteProduct,
  createProduct,
  toggleProductStatus
} from 'services/productService';
import { IconButton, CircularProgress } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ProductForm from './ProductForm';
import SearchBar from 'components/SearchBar';
import SortSelector from 'components/SortSelector';
import { useProducts } from 'context/ProductsContext';

/**
 * Remove acentos/diacríticos de uma string, permitindo comparar
 * "Pérgola" e "Pergola" de forma equivalente.
 */
const removeDiacritics = (text) => {
  return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

const formatCurrency = (value) => {
  const numberValue = parseFloat(value);
  if (isNaN(numberValue)) return 'R$ 0,00';

  const adjustedValue = numberValue / 100;
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(adjustedValue);
};

const ProductSection = () => {
  const { products, setProducts } = useProducts();
  const [isLoading, setIsLoading] = useState(products.length === 0);
  const [showForm, setShowForm] = useState(false);
  const [activeProductId, setActiveProductId] = useState(null);
  const menuRefs = useRef({});

  // Estados para filtro e ordenação
  const [searchQuery, setSearchQuery] = useState('');
  const [sortOption, setSortOption] = useState('');

  // Busca inicial dos produtos caso o contexto esteja vazio
  useEffect(() => {
    if (products.length === 0) {
      (async () => {
        try {
          const productsData = await getAllProducts();
          setProducts(productsData);
        } catch (error) {
          console.error('Erro ao carregar produtos:', error.message);
        } finally {
          setIsLoading(false);
        }
      })();
    } else {
      setIsLoading(false);
    }
  }, [products, setProducts]);

  const handleSearchChange = (query) => {
    // Deixamos a conversão para lowercase aqui mesmo
    setSearchQuery(query.toLowerCase());
  };

  const handleSortChange = (event) => {
    setSortOption(event.target.value);
  };

  const handleDelete = async (productId, event) => {
    event.stopPropagation();
    const confirmDelete = window.confirm('Tem certeza que deseja deletar este produto?');
    if (confirmDelete) {
      try {
        await deleteProduct(productId);
        alert('Produto deletado com sucesso!');
        // Força recarregamento para atualizar lista
        window.location.reload();
      } catch (error) {
        console.error('Erro ao deletar produto:', error.message);
      }
    }
  };

  const handleToggleStatus = async (productId, currentStatus, event) => {
    event.stopPropagation();
    const action = currentStatus ? 'desativar' : 'ativar';
    const confirmToggle = window.confirm(`Tem certeza que deseja ${action} este produto?`);
    if (!confirmToggle) return;

    try {
      await toggleProductStatus(productId, currentStatus);
      alert('Status do produto alterado com sucesso!');
      // Força recarregamento para atualizar lista
      window.location.reload();
    } catch (error) {
      console.error('Erro ao alternar status do produto:', error.message);
    }
  };

  const handleSubmitProduct = async (newProduct, imageFiles) => {
    try {
      await createProduct(newProduct, imageFiles);
      alert('Produto cadastrado com sucesso!');
      const productsData = await getAllProducts();
      setProducts(productsData);
      setShowForm(false);
    } catch (error) {
      console.error('Erro ao cadastrar produto:', error.message);
    }
  };

  const toggleOptions = (productId) => {
    setActiveProductId((prevId) => (prevId === productId ? null : productId));
  };

  // Fecha o menu de opções ao clicar fora
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        activeProductId &&
        menuRefs.current[activeProductId] &&
        !menuRefs.current[activeProductId].contains(event.target) &&
        !event.target.closest('.delete-button')
      ) {
        setActiveProductId(null);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [activeProductId]);

  // Filtra e ordena os produtos conforme o termo de busca e a opção de ordenação selecionada
  const finalProducts = useMemo(() => {
    // Normalizamos apenas para comparação de busca, sem mudar a ortografia original
    const normalizedSearch = removeDiacritics(searchQuery);

    const filtered = products.filter((product) => {
      const normalizedName = removeDiacritics(product.name.toLowerCase());
      const normalizedDesc = removeDiacritics(product.description.toLowerCase());
      return (
        normalizedName.includes(normalizedSearch) ||
        normalizedDesc.includes(normalizedSearch)
      );
    });

    if (sortOption === 'price-asc') {
      filtered.sort((a, b) => a.promotional_price - b.promotional_price);
    } else if (sortOption === 'price-desc') {
      filtered.sort((a, b) => b.promotional_price - a.promotional_price);
    } else if (sortOption === 'name-asc') {
      filtered.sort((a, b) => a.name.localeCompare(b.name));
    } else if (sortOption === 'name-desc') {
      filtered.sort((a, b) => b.name.localeCompare(a.name));
    }

    return filtered;
  }, [products, searchQuery, sortOption]);

  return (
    <section className="bg-white min-h-svh p-4 pb-20">
      {!showForm && (
        <button
          onClick={() => setShowForm(true)}
          className="bg-blue-500 text-white p-4 rounded-full text-lg font-medium fixed bottom-24 md:bottom-4 right-4 z-50 shadow-lg hover:bg-blue-600 transition-colors"
          aria-label="Cadastrar novo produto"
        >
          + Novo Produto
        </button>
      )}

      {showForm ? (
        <ProductForm
          onSubmit={handleSubmitProduct}
          onCancel={() => setShowForm(false)}
        />
      ) : (
        <>
          <header className="flex flex-col md:flex-row md:items-center justify-between mb-4">
            <div className="flex items-center gap-2">
              <h1 className="text-2xl font-semibold">Produtos</h1>
              <span className="text-xl font-medium text-gray-600">
                ({finalProducts.length} produtos encontrados)
              </span>
            </div>
            <div className="flex gap-2 mt-2 md:mt-0">
              <SearchBar onSearchChange={handleSearchChange} />
              <SortSelector sortOption={sortOption} onSortChange={handleSortChange} />
            </div>
          </header>

          {isLoading ? (
            <div className="flex justify-center items-center mt-8">
              <CircularProgress color="primary" />
              <span className="ml-2 text-gray-500">Carregando produtos...</span>
            </div>
          ) : products.length > 0 ? (
            finalProducts.length > 0 ? (
              <ul className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                {finalProducts.map((product) => (
                  <li
                    key={product.id}
                    className={`relative p-4 rounded-lg shadow-md transition duration-500 transform scale-100 cursor-pointer ${
                      product.is_active ? 'bg-sand' : 'bg-[#eee0c2]'
                    }`}
                  >
                    <div className="flex justify-between items-start">
                      <div>
                        {/* Mantemos a ortografia original, com acentos */}
                        <h2 className="text-lg font-bold text-gray-800">
                          {product.name}
                        </h2>
                        <p
                          className="mt-1 text-sm text-gray-600 line-clamp-2"
                          title={product.description}
                        >
                          {product.description}
                        </p>
                      </div>
                      <div className="ml-2">
                        <IconButton
                          onClick={() => toggleOptions(product.id)}
                          ref={(el) => (menuRefs.current[product.id] = el)}
                          aria-label="Mais opções"
                        >
                          <MoreVertIcon />
                        </IconButton>
                        {activeProductId === product.id && (
                          <div
                            onMouseDown={(e) => e.stopPropagation()}
                            className="absolute right-0 top-8 mt-1 w-36 rounded-lg shadow-lg bg-white border border-gray-200 z-10"
                          >
                            <button
                              onClick={(event) => handleDelete(product.id, event)}
                              className="block w-full text-center p-2 text-red-600 font-medium hover:bg-red-100 rounded delete-button"
                              aria-label="Deletar produto"
                            >
                              Deletar
                            </button>
                            <button
                              onClick={(event) =>
                                handleToggleStatus(product.id, product.is_active, event)
                              }
                              className="block w-full text-center p-2 text-blue-600 font-medium hover:bg-blue-100 rounded"
                              aria-label="Alternar status do produto"
                            >
                              {product.is_active ? 'Desativar' : 'Ativar'}
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="mt-4">
                      <span className="text-lg font-semibold text-gray-900">
                        {formatCurrency(product.promotional_price)}
                      </span>
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <p className="text-gray-500 text-center mt-8">
                Nenhum produto encontrado.
              </p>
            )
          ) : (
            <p className="text-gray-500 text-center mt-8">
              Nenhum produto cadastrado.
            </p>
          )}
        </>
      )}
    </section>
  );
};

export default ProductSection;