import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import API_BASE_URL from 'config/apiConfig';

const API_URL = `${API_BASE_URL}/uploads/`;

const ProductCard = React.memo(({ product }) => {
  const { id, name, price, promotional_price, product_images } = product;
  const imageUrl =
    product_images && product_images.length > 0
      ? product_images[0].image_url
      : 'default-image.jpg';

  const [imageLoaded, setImageLoaded] = useState(false);
  const navigate = useNavigate();

  // Atualiza o estado assim que a imagem carregar
  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  const handleClick = () => {
    navigate(`/produto/${id}`, { state: { product } });
  };

  // Função para formatar valores em reais
  const formatCurrency = (value) => {
    const numberValue = parseFloat(value);
    if (isNaN(numberValue)) return 'R$ 0,00';
    const adjustedValue = numberValue / 100;
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(adjustedValue);
  };

  return (
    <div
      className="relative flex flex-col justify-between items-center text-center bg-white border-2 border-gray-300 text-black overflow-hidden w-full cursor-pointer shadow-2xl"
      onClick={handleClick}
    >
      <div className="relative w-full">
        <picture className="w-full">
          <img
            className="w-full h-full object-cover bg-white aspect-square transition-opacity duration-500 opacity-100 my-4"
            // Removida a lógica condicional de opacidade baseada em imageLoaded
            src={`${API_URL}${imageUrl}`}
            alt={name}
            loading="lazy"
            onLoad={handleImageLoad}
          />
          {/*
          // Skeleton da imagem comentado:
          {!imageLoaded && (
            <img
              className="absolute top-0 left-0 w-full h-full object-cover bg-white aspect-square"
              src={loading}
              alt="Carregando..."
            />
          )}
          */}
        </picture>
      </div>

      <div className="p-4 w-full text-black">
        {/*
        // Skeleton para as informações do produto comentado:
        {!imageLoaded ? (
          <div className="animate-pulse">
            <div className="h-6 bg-gray-300 mb-2 w-2/3"></div> // Skeleton do título
            <div className="h-4 bg-gray-300 mb-1 w-1/2"></div> // Skeleton do preço
          </div>
        ) : (
        */}
          <div className="flex flex-col gap-1 mb-2">
            <h2 className="text-xl">{name}</h2>
            <span className="text-2xl font-semibold">
              {formatCurrency(promotional_price || price)}
            </span>
          </div>
        {/*
        )
        */}
      </div>

      {/*
      // Skeleton para o botão comentado:
      {imageLoaded ? (
        <button className="bg-green text-white text-xl font-bold p-4 w-full hover:opacity-75 transition-opacity duration-500">
          Compre Já!
        </button>
      ) : (
        <div className="w-full h-12 bg-gray-300 animate-pulse"></div>
      )}
      */}
      <button className="bg-green text-white text-xl font-bold p-4 w-full hover:opacity-75 transition-opacity duration-500">
        Compre Já!
      </button>
    </div>
  );
});

export default ProductCard;