import React, { useEffect, useState, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useProducts } from 'context/ProductsContext';
import ProductCard from './components/ProductCard';
import SearchBar from 'components/SearchBar';
import SortSelector from 'components/SortSelector';

import Header from 'components/Header';
import Contact from 'components/Contact';
import Footer from 'components/Footer';

// Função para remover acentos (diacríticos) sem alterar a exibição
const removeDiacritics = (text) => {
  return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

const Products = () => {
  const { products, isFetched } = useProducts();
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortOption, setSortOption] = useState('');
  const location = useLocation();

  // Função para extrair a subcategoria da URL
  const getSubcategoryFromUrl = () => {
    const params = new URLSearchParams(location.search);
    return params.get('subcategory');
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // Se os produtos já foram buscados, aplica o filtro de subcategoria
    if (isFetched) {
      const subcategoryId = getSubcategoryFromUrl();
      if (subcategoryId) {
        const filtered = products.filter((product) =>
          product.product_subcategories.some(
            (sub) => sub.subcategories.id.toString() === subcategoryId
          )
        );
        setFilteredProducts(filtered);
      } else {
        setFilteredProducts(products);
      }
    }
  }, [location.search, isFetched, products]);

  const handleSearchChange = (query) => {
    setSearchQuery(query.toLowerCase());
  };

  const handleSortChange = (event) => {
    setSortOption(event.target.value);
  };

  // Retorna o preço efetivo do produto (promocional se disponível ou preço normal)
  const getEffectivePrice = (product) =>
    product.promotional_price || product.price;

  // Aplica os filtros de busca e ordenação, utilizando a remoção de acentos somente para comparação
  const finalProducts = useMemo(() => {
    const normalizedSearch = removeDiacritics(searchQuery);
    return filteredProducts
      .filter((product) => {
        if (!searchQuery) return true;
        const normalizedName = removeDiacritics(product.name.toLowerCase());
        return normalizedName.includes(normalizedSearch);
      })
      .sort((a, b) => {
        if (sortOption === 'price-asc') {
          return getEffectivePrice(a) - getEffectivePrice(b);
        }
        if (sortOption === 'price-desc') {
          return getEffectivePrice(b) - getEffectivePrice(a);
        }
        return 0;
      });
  }, [filteredProducts, searchQuery, sortOption]);

  return (
    <main className="flex flex-col justify-center items-center w-full bg-[#F5F5F5]">
      <Header />

      <div className="flex my-24 w-11/12 md:w-9/12 min-h-svh">
        <div className="flex-grow p-4 w-full">
          <div className="flex justify-end gap-2 items-center mb-8">
            <SearchBar onSearchChange={handleSearchChange} />
            <SortSelector sortOption={sortOption} onSortChange={handleSortChange} />
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
            {finalProducts.length > 0 ? (
              finalProducts.map((product) => (
                <ProductCard key={product.id} product={product} />
              ))
            ) : (
              isFetched && <p className="w-full text-center">Nenhum produto encontrado</p>
            )}
          </div>
        </div>
      </div>
      <Contact />
      <Footer />
    </main>
  );
};

export default Products;