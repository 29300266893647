import React, { useEffect } from 'react';

// global components
import Header from 'components/Header';
import Footer from 'components/Footer';
import Contact from 'components/Contact';
import WhatsApp from 'components/WhatsApp';

// assets
import about from 'assets/images/about-teste.png';

function AboutUs() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = 'Empório Maziero - Quem Somos';
  }, []);

  return (
    <section className='flex flex-col min-h-svh w-full items-center justify-center bg-white text-black'>
      <Header />
      <div className='my-8 flex w-11/12 flex-col items-center justify-center gap-4 md:w-9/12 mt-28'>
        <div className='flex w-full flex-col items-center justify-between gap-16 md:flex-row'>
          <aside className='flex flex-1 flex-col gap-4'>
            <header className='mb-4 text-4xl font-bold font-juana md:text-5xl'>Quem Somos?</header>
            <p className='text-justify text-lg md:text-xl'>
              O <strong>Empório Maziero</strong> nasceu há 4 anos com um propósito simples e transformador: celebrar a enogastronomia e os momentos especiais que ela proporciona. Apreciar um bom vinho sempre foi parte da nossa essência, e entendemos que ele não é apenas uma bebida, mas uma experiência que conecta pessoas, desperta emoções e cria memórias inesquecíveis.
            </p>
            <p className='text-justify text-lg md:text-xl'>
              Hoje, com 4 anos de história, nosso objetivo é claro: levar emoção ao apreciar um bom vinho, reunindo aqueles que você mais ama. Valorizamos cada momento da jornada do cliente, desde o primeiro contato, até a entrega de um atendimento personalizado e atencioso. Além disso, oferecemos cursos sobre a arte da harmonização, ajudando nossos clientes a descobrir o prazer de combinar vinhos com os momentos únicos da vida.
            </p>
          </aside>
          <img src={about} className='w-96 rounded-xl' loading='lazy' alt='Imagem ilustrativa sobre quem somos' />
        </div>
        <div className='flex flex-col gap-8'>
          <article className='flex flex-col gap-2'>
            <header className='text-2xl font-bold md:text-3xl font-juana'>Nossa Missão</header>
            <p className='w-full text-justify text-lg md:text-xl'>
              Oferecer aos nossos clientes uma experiência única no mundo dos vinhos, entregando produtos de qualidade,
              com atendimento especializado, ensinando sobre harmonização e sempre criando um laço de confiança e parceria.
              Queremos que cada taça seja uma oportunidade de reunir amigos e familiares em um ambiente aconchegante e especial.
            </p>
          </article>
          <article className='flex flex-col gap-2'>
            <header className='text-2xl font-bold md:text-3xl font-juana'>Nossa Visão</header>
            <p className='w-full text-justify text-lg md:text-xl'>
              Transformar o Empório Maziero em um local de referência não só para encontrar vinhos e cervejas de qualidade,
              mas também um espaço acolhedor para encontros, jantares, comemorações e reuniões restritas. Nosso objetivo é
              proporcionar uma experiência completa, desde a escolha da bebida até os momentos especiais que ela pode criar.
            </p>
          </article>
          <article className='flex flex-col gap-2'>
            <header className='text-2xl font-bold md:text-3xl font-juana'>Nossos Valores</header>
            <p className='w-full text-justify text-lg md:text-xl'>
              Paixão pelo vinho, atendimento de excelência, confiança, respeito e compromisso com a qualidade.
            </p>
          </article>
        </div>
      </div>
      <Contact />
      <Footer />
      <WhatsApp />
    </section>
  );
}

export default AboutUs; 