import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getAllProducts } from 'services/productService';
import loading from 'assets/images/loading.svg';
import API_BASE_URL from 'config/apiConfig';

const API_URL = `${API_BASE_URL}/uploads/`;

const ProductCard = ({ product }) => {
  const { id, name, price, promotional_price, product_images } = product;
  const imageUrl = product_images.length > 0 ? product_images[0].image_url : 'default-image.jpg';

  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  const handleClick = () => {
    navigate(`/produto/${id}`, { state: { product } });
  };

  // Função para formatar valores em reais
  const formatCurrency = (value) => {
    const numberValue = parseFloat(value);
    if (isNaN(numberValue)) return 'R$ 0,00';
    const adjustedValue = numberValue / 100;
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(adjustedValue);
  };

  return (
    <div
      className="flex flex-col justify-between items-center text-center bg-white border-2 border-gray-300 text-black overflow-hidden w-full cursor-pointer shadow-2xl"
      onClick={handleClick}
    >
      {/* Skeleton para imagem */}
      <picture className="w-full">
        {isLoading ? (
          <img
            className="w-full h-full object-cover bg-white aspect-square transition-opacity duration-1000 opacity-100"
            src={loading}
            alt={name}
          />
        ) : (
          <img
            className="w-full h-full object-cover my-4 bg-white aspect-square transition-opacity duration-1000 opacity-100"
            src={`${API_URL}${imageUrl}`}
            alt={name}
          />
        )}
      </picture>

      {/* Skeleton para informações do produto */}
      <div className="p-4 w-full text-black">
        {isLoading ? (
          <div className="animate-pulse">
            <div className="h-6 bg-gray-300 mb-2 w-2/3"></div> {/* Skeleton do título */}
            <div className="h-4 bg-gray-300 mb-1 w-1/2"></div> {/* Skeleton do preço */}
          </div>
        ) : (
          <div className="flex flex-col gap-1 mb-2">
            {/* Caso queira exibir o preço original riscado quando houver promoção, descomente o trecho abaixo */}
            {/*
            {promotional_price && (
              <span className="text-slate-400 text-md font-semibold line-through">
                {formatCurrency(price)}
              </span>
            )}
            */}
            <h2 className="text-xl">{name}</h2>
            <span className="text-2xl font-semibold">
              {formatCurrency(promotional_price || price)}
            </span>
          </div>
        )}
      </div>

      {/* Skeleton para o botão */}
      {isLoading ? (
        <div className="w-full h-12 bg-gray-300 animate-pulse"></div>
      ) : (
        <button className="bg-green text-white text-xl font-bold p-4 w-full hover:opacity-75 transition-opacity duration-500">
          Compre Já!
        </button>
      )}
    </div>
  );
};

const Promotions = () => {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const location = useLocation();

  // Função para extrair a subcategoria da URL
  const getSubcategoryFromUrl = () => {
    const params = new URLSearchParams(location.search);
    return params.get('subcategory');
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = 'Empório Maziero - Produtos';

    const fetchProducts = async () => {
      try {
        const productsData = await getAllProducts();
        // Filtra produtos pela subcategoria, se presente na URL
        const subcategoryId = getSubcategoryFromUrl();
        if (subcategoryId) {
          const filtered = productsData.filter((product) =>
            product.product_subcategories.some(
              (sub) => sub.subcategories.id.toString() === subcategoryId
            )
          );
          setFilteredProducts(filtered);
        } else {
          setFilteredProducts(productsData);
        }
      } catch (error) {
        console.error('Erro ao buscar produtos:', error);
      }
    };

    fetchProducts();
  }, [location.search]);

  const handleSearchChange = (query) => {
    setSearchQuery(query.toLowerCase());
  };

  // Filtra os produtos conforme a busca e ordena pelo menor valor (usando promotional_price se existir)
  const finalProducts = filteredProducts
    .filter((product) =>
      !searchQuery || product.name.toLowerCase().includes(searchQuery)
    )
    .sort((a, b) => {
      const effectivePriceA = a.promotional_price || a.price;
      const effectivePriceB = b.promotional_price || b.price;
      return effectivePriceA - effectivePriceB;
    });

  // Seleciona os 3 produtos com o menor valor
  const displayedProducts = finalProducts.slice(0, 3);

  return (
    <main className="flex flex-col justify-center items-center w-full bg-[#F5F5F5]">
      <div className="flex my-24 w-11/12 md:w-9/12">
        <div className="flex-grow p-4 w-full">
          {/* Título e subtítulo alinhados à esquerda */}
          <div className="mb-8 text-left">
            <h1 className="text-2xl font-semibold md:text-5xl font-juana">Promoções</h1>
            <p className="text-lg md:text-2xl">Aproveite, por tempo limitado!</p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
            {displayedProducts.length > 0 ? (
              displayedProducts.map((product) => (
                <ProductCard key={product.id} product={product} />
              ))
            ) : (
              <p className="w-full text-center">Nenhum produto encontrado</p>
            )}
          </div>
        </div>
      </div>
    </main>
  );
};

export default Promotions;
